.checkbox-btn, .radio-btn {
	position: relative;
	padding-left: 20px
}

.checkbox-btn input[type="checkbox"], .checkbox-btn input[type="radio"],
	.radio-btn input[type="checkbox"], .radio-btn input[type="radio"] {
	position: absolute;
	opacity: 0;
	z-index: 1
}

.checkbox-btn label, .radio-btn label {
	display: inline-block;
	line-height: 24px;
	vertical-align: middle;
	position: relative;
	padding-left: 20px;
	cursor: pointer;
	user-select: none;
	color: #424242
}

.checkbox-btn label::after, .radio-btn label::after {
	position: absolute;
	content: "";
	width: 24px;
	height: 24px;
	left: 0;
	top: 0;
	margin-left: -18px;
	background-color: white;
	border: 2px solid #BDBDBD;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-clip: padding-box;
	cursor: pointer;
}

.checkbox-btn label::before, .radio-btn label::before {
	position: absolute;
	content: "";
	width: 20px;
	height: 10px;
	top: 5px;
	left: 5px;
	margin-left: -18px;
	border: 2px solid #000;
	border-top: none;
	border-right: none;
	background: transparent;
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	opacity: 0;
	-webkit-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	z-index: 1
}

.checkbox-btn label:active::before, .radio-btn label:active::before {
	opacity: 0.3
}

.checkbox-btn input[type="checkbox"]:checked+label, .checkbox-btn input[type="radio"]:checked+label,
	.radio-btn input[type="checkbox"]:checked+label, .radio-btn input[type="radio"]:checked+label
	{
	color: #000
}

.checkbox-btn input[type="checkbox"]:checked+label::after, .checkbox-btn input[type="radio"]:checked+label::after,
	.radio-btn input[type="checkbox"]:checked+label::after, .radio-btn input[type="radio"]:checked+label::after
	{
	border: 2px solid #000
}

.checkbox-btn input[type="checkbox"]:checked+label::before,
	.checkbox-btn input[type="radio"]:checked+label::before, .radio-btn input[type="checkbox"]:checked+label::before,
	.radio-btn input[type="radio"]:checked+label::before {
	opacity: 1
}

.checkbox-btn input[type="checkbox"]:disabled+label, .checkbox-btn input[type="radio"]:disabled+label,
	.radio-btn input[type="checkbox"]:disabled+label, .radio-btn input[type="radio"]:disabled+label
	{
	color: #787878;
	cursor: default
}

.checkbox-btn input[type="checkbox"]:disabled+label::after,
	.checkbox-btn input[type="radio"]:disabled+label::after, .radio-btn input[type="checkbox"]:disabled+label::after,
	.radio-btn input[type="radio"]:disabled+label::after {
	border: 3px solid #787878;
	background-color: #ffffff
}

.checkbox-btn input[type="checkbox"]:disabled+label::before,
	.checkbox-btn input[type="radio"]:disabled+label::before, .radio-btn input[type="checkbox"]:disabled+label::before,
	.radio-btn input[type="radio"]:disabled+label::before {
	display: none
}

.radio-btn label::after {
	width: 24px;
	height: 24px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-clip: padding-box;
	border-color: #BDBDBD;
}

.radio-btn label::before {
	width: 14px;
	height: 14px;
	border: none;
	top: 5px;
	left: 5px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-clip: padding-box;
	background-color: #0F93F5;
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	font-size: 24px;
}

.radio-btn input[type='radio']:checked+label::after {
	border-color: #0F93F5;
}

/* checkbox-v2: make the checkbox button style similar with the radio button, 
just change the cycle to rectangle */
.checkbox-btn label::before {
	width: 14px;
	height: 14px;
	border: none;
	top: 5px;
	left: 5px;
	background-clip: padding-box;
	background-color: #0F93F5;
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	font-size: 24px;
}

.checkbox-btn input[type='checkbox']:checked+label::after {
	border-color: #0F93F5;
}
/* checkbox-v2: end */

/*# sourceMappingURL=checkbox.css.map */